import { isProduction } from '@exchange/helpers/environment';

type OptionsFlags<Type> = {
  [Property in keyof Type]: Type[Property];
};

export type PromoConfigFlag = {
  start: string;
  end: string;
};

export type PlatformUpgrade = {
  showBannerFrom: string;
  start: string;
  end: string;
};

export type KryptviewFlag = {
  from: string;
  to: string;
  starting: string;
  enabled: boolean;
  link: string;
};

export type PartnerPromoNotificationFlag = {
  startsAt: number;
  endsAt: number;
};

export type FeLoggingFlag = {
  auth: boolean;
  orderbook: boolean;
  tv: boolean;
  wss: boolean;
};

export enum PromoFlag {
  market = 'market-promo',
}

export type CapacitorUpdaterFlag = {
  enabled: boolean;
  delay: number;
};

export type TrendingMarketsFlag = {
  numberToShow: number;
};

export type VerificationFlag = {
  ready: boolean;
  link: string;
};

export const featureFlagsDefaults = {
  'balances-chart-default-period': 'DAY',
  'capacitor-updater': {
    enabled: false,
    delay: 5,
  },
  'charting-library-go-to-date': false,
  'featured-fiat-currencies': {
    'market-list': ['EUR', 'CHF', 'GBP'],
    settings: ['EUR', 'CHF', 'GBP'],
  },
  feLogging: {
    auth: false,
    orderbook: false,
    tv: false,
    wss: true,
  },
  'force-app-update': false,
  'force-app-update-delay': 2,
  kryptview: {
    from: '2002-01-01',
    to: '2002-01-01',
    starting: '2002-01-01',
    enabled: false,
    link: 'https://kryptview.com/',
  },
  'is-futures-page-enabled': !isProduction,
  'is-card-payment-enabled': !isProduction,
  'market-orders': false,
  'my-orders-open-limit': 200,
  'myOrders-tracing': false,
  'orderbook-fps': 15,
  'orderbook-tracing': false,
  'partner-promo-notifications': {},
  'platform-upgrade': {
    from: '2024-01-01T00:00:00.000Z',
    to: '2024-01-04T23:00:00.000Z',
    showBannerFrom: '2024-01-01T00:00:00.000Z',
  },
  'platform-upgrade-prep': {
    end: '2023-12-22T00:00:00.000Z',
    start: '2023-12-19T00:00:00.000Z',
  },
  'settlement-delay': {
    end: '2024-01-01T23:00:00.000Z', // CET time; 22nd Dec to 1st Jan inclusive please. Midnight to midnight
    start: '2023-12-21T23:00:00.000Z',
  },
  'throttle-ws': {
    balance: 1500,
    'market-list': 100,
    'my-orders-open': 100,
    'my-orders-filled': 100,
    'order-book': 100,
  },
  'trending-markets': {
    numberToShow: 20,
  },
  'uk-fca': {
    start: '2023-10-08T00:00:00.000Z',
  },
  [PromoFlag.market]: { start: '10', end: '1' },
  verification: {
    ready: false,
    link: 'https://support.onetrading.com',
  },
  'terms-and-conditions-date': {
    publishedDate: '2024-11-05',
    effectiveDate: '2024-12-07',
  },
  'universal-login': false,
  'withdrawal-travel-rules': false,
};

export type FeatureFlags = OptionsFlags<typeof featureFlagsDefaults>;
